import { ACTIONS, PERMISSION_ENUM, ROLE_ENUM } from 'shared/enums/permission';

/**
 * The `ROLE_BASED_ACCESS_CONTROL` constant is an object that defines the permissions for different
 * roles in the application. It uses TypeScript's `Record` type to ensure that the keys of the object
 * are of type `ROLE_ENUM` and the values are arrays of type `PERMISSION_ENUM`.
 */
export const ROLE_BASED_ACCESS_CONTROL: Record<ROLE_ENUM, readonly PERMISSION_ENUM[]> = {
  [ROLE_ENUM.ADMIN]: [PERMISSION_ENUM.ALL],
  [ROLE_ENUM.LMS_TEAM_LEAD]: [PERMISSION_ENUM.ALL],
  [ROLE_ENUM.SHIPPING_MANAGER]: [PERMISSION_ENUM.ALL],
  [ROLE_ENUM.SPECIALIST_TEAM]: [PERMISSION_ENUM.ALL],
  [ROLE_ENUM.SHIPPING_SPECIALIST]: [PERMISSION_ENUM.ALL],
  [ROLE_ENUM.SHIPPING_TEAM_LEAD]: [PERMISSION_ENUM.ALL],
  [ROLE_ENUM.CSR]: [
    PERMISSION_ENUM.VISIBLE,
    PERMISSION_ENUM.READ,
    PERMISSION_ENUM.UPDATE,
    PERMISSION_ENUM.CREATE,
    PERMISSION_ENUM.DELETE,
  ],
  [ROLE_ENUM.CSR_MANAGER]: [
    PERMISSION_ENUM.VISIBLE,
    PERMISSION_ENUM.READ,
    PERMISSION_ENUM.UPDATE,
    PERMISSION_ENUM.CREATE,
    PERMISSION_ENUM.DELETE,
  ],
  [ROLE_ENUM.ACCOUNTING]: [
    PERMISSION_ENUM.VISIBLE,
    PERMISSION_ENUM.READ,
    PERMISSION_ENUM.UPDATE,
    PERMISSION_ENUM.CREATE,
    PERMISSION_ENUM.DELETE,
  ],
  [ROLE_ENUM.INTERNAL_LOGISTICS_TEAM_LEAD]: [PERMISSION_ENUM.ALL],
  [ROLE_ENUM.TEAM_LEAD]: [PERMISSION_ENUM.ALL],
  [ROLE_ENUM.PRODUCTION_QC]: [PERMISSION_ENUM.VISIBLE],
  [ROLE_ENUM.PRODUCTION_TECHNITIAN]: [PERMISSION_ENUM.VISIBLE],
  [ROLE_ENUM.PRODUCTION_SCHEDULER]: [
    PERMISSION_ENUM.CREATE,
    PERMISSION_ENUM.DELETE,
    PERMISSION_ENUM.READ,
    PERMISSION_ENUM.UPDATE,
    PERMISSION_ENUM.VISIBLE,
  ],
  [ROLE_ENUM.PRODUCTION_MANAGER]: [PERMISSION_ENUM.ALL],
  [ROLE_ENUM.PRODUCTION_TEAM_LEAD]: [PERMISSION_ENUM.ALL],
  [ROLE_ENUM.DISTRIBUTION]: [PERMISSION_ENUM.VISIBLE],
  [ROLE_ENUM.TECHNICAL_ADVISOR]: [PERMISSION_ENUM.ALL],
  [ROLE_ENUM.METAL_ENTRY]: [PERMISSION_ENUM.ALL],
  [ROLE_ENUM.PRODUCTION_ADMIN]: [PERMISSION_ENUM.ALL],
  [ROLE_ENUM.EVALUATION]: [PERMISSION_ENUM.VISIBLE, PERMISSION_ENUM.UPDATE, PERMISSION_ENUM.READ],
  [ROLE_ENUM.HOTDESK]: [PERMISSION_ENUM.ALL],
  [ROLE_ENUM.ORDER_PROCESSOR_1]: [PERMISSION_ENUM.VISIBLE],
  [ROLE_ENUM.ORDER_PROCESSOR_2]: [PERMISSION_ENUM.ALL],
} as const;

/**
 * The `APP_PERMISSION_LIST` constant is an object that defines different permissions for specific
 * actions or features in the application. It maps each action or feature to an array of roles that
 * have permission to perform that action or access that feature.
 */
export const APP_PERMISSION_LIST = {
  APP_LEVEL: Object.keys(ROLE_BASED_ACCESS_CONTROL) as ROLE_ENUM[], // Allow access permission based on all roles. This is used for the app level.
  [ACTIONS.UNINVOICE]: [
    ROLE_ENUM.ADMIN,
    ROLE_ENUM.LMS_TEAM_LEAD,
    ROLE_ENUM.SHIPPING_SPECIALIST,
    ROLE_ENUM.SHIPPING_MANAGER,
    ROLE_ENUM.HOTDESK,
    ROLE_ENUM.SHIPPING_TEAM_LEAD,
  ],
  [ACTIONS.RESTORE_CANCELED_CASE]: [
    ROLE_ENUM.HOTDESK,
    ROLE_ENUM.PRODUCTION_MANAGER,
    ROLE_ENUM.LMS_TEAM_LEAD,
    ROLE_ENUM.PRODUCTION_SCHEDULER,
    ROLE_ENUM.TECHNICAL_ADVISOR,
  ],
  [ACTIONS.RESTORE_DELETED_CASE]: [ROLE_ENUM.SHIPPING_MANAGER, ROLE_ENUM.HOTDESK],
  [ACTIONS.EDIT_ALLOY_TYPE]: [
    ROLE_ENUM.ADMIN,
    ROLE_ENUM.PRODUCTION_MANAGER,
    ROLE_ENUM.SPECIALIST_TEAM,
    ROLE_ENUM.METAL_ENTRY,
  ],
  [ACTIONS.EDIT_ALLOY_WEIGHT]: [ROLE_ENUM.ADMIN, ROLE_ENUM.PRODUCTION_MANAGER, ROLE_ENUM.METAL_ENTRY],
  [ACTIONS.INVOICE_CASE]: [
    ROLE_ENUM.ADMIN,
    ROLE_ENUM.SHIPPING_MANAGER,
    ROLE_ENUM.SHIPPING_SPECIALIST,
    ROLE_ENUM.HOTDESK,
    ROLE_ENUM.ORDER_PROCESSOR_2,
    ROLE_ENUM.LMS_TEAM_LEAD,
    ROLE_ENUM.SHIPPING_TEAM_LEAD,
  ],
  [ACTIONS.CANCEL_CASE]: [
    ROLE_ENUM.ADMIN,
    ROLE_ENUM.PRODUCTION_MANAGER,
    ROLE_ENUM.PRODUCTION_SCHEDULER,
    ROLE_ENUM.SHIPPING_MANAGER,
    ROLE_ENUM.TECHNICAL_ADVISOR,
    ROLE_ENUM.HOTDESK,
    ROLE_ENUM.LMS_TEAM_LEAD,
    ROLE_ENUM.SPECIALIST_TEAM,
    ROLE_ENUM.INTERNAL_LOGISTICS_TEAM_LEAD,
    ROLE_ENUM.SHIPPING_TEAM_LEAD,
  ],
  [ACTIONS.CREATE_CASE]: [
    ROLE_ENUM.ADMIN,
    ROLE_ENUM.SHIPPING_MANAGER,
    ROLE_ENUM.SHIPPING_SPECIALIST,
    ROLE_ENUM.HOTDESK,
    ROLE_ENUM.ORDER_PROCESSOR_2,
    ROLE_ENUM.LMS_TEAM_LEAD,
    ROLE_ENUM.SHIPPING_TEAM_LEAD,
  ],
  [ACTIONS.EDIT_CASE]: [
    ROLE_ENUM.ADMIN,
    ROLE_ENUM.CSR,
    ROLE_ENUM.CSR_MANAGER,
    ROLE_ENUM.ACCOUNTING,
    ROLE_ENUM.SHIPPING_MANAGER,
    ROLE_ENUM.SHIPPING_SPECIALIST,
    ROLE_ENUM.PRODUCTION_MANAGER,
    ROLE_ENUM.TEAM_LEAD,
    ROLE_ENUM.PRODUCTION_SCHEDULER,
    ROLE_ENUM.TECHNICAL_ADVISOR,
    ROLE_ENUM.METAL_ENTRY,
    ROLE_ENUM.PRODUCTION_ADMIN,
    ROLE_ENUM.HOTDESK,
    ROLE_ENUM.ORDER_PROCESSOR_2,
    ROLE_ENUM.LMS_TEAM_LEAD,
    ROLE_ENUM.SPECIALIST_TEAM,
    ROLE_ENUM.INTERNAL_LOGISTICS_TEAM_LEAD,
    ROLE_ENUM.EVALUATION,
    ROLE_ENUM.PRODUCTION_TEAM_LEAD,
    ROLE_ENUM.SHIPPING_TEAM_LEAD,
  ],
  [ACTIONS.DELETE_CASE]: [
    ROLE_ENUM.ADMIN,
    ROLE_ENUM.SHIPPING_MANAGER,
    ROLE_ENUM.HOTDESK,
    ROLE_ENUM.LMS_TEAM_LEAD,
    ROLE_ENUM.SPECIALIST_TEAM,
    ROLE_ENUM.INTERNAL_LOGISTICS_TEAM_LEAD,
    ROLE_ENUM.TECHNICAL_ADVISOR,
    ROLE_ENUM.SHIPPING_TEAM_LEAD,
    ROLE_ENUM.PRODUCTION_MANAGER,
  ],
  [ACTIONS.PRINT_WORKORDER_CASEPAN]: [
    ROLE_ENUM.ADMIN,
    ROLE_ENUM.SHIPPING_MANAGER,
    ROLE_ENUM.SHIPPING_SPECIALIST,
    ROLE_ENUM.PRODUCTION_SCHEDULER,
    ROLE_ENUM.TECHNICAL_ADVISOR,
    ROLE_ENUM.HOTDESK,
    ROLE_ENUM.ORDER_PROCESSOR_2,
    ROLE_ENUM.LMS_TEAM_LEAD,
    ROLE_ENUM.SPECIALIST_TEAM,
    ROLE_ENUM.INTERNAL_LOGISTICS_TEAM_LEAD,
    ROLE_ENUM.EVALUATION,
    ROLE_ENUM.SHIPPING_TEAM_LEAD,
    ROLE_ENUM.PRODUCTION_TEAM_LEAD,
  ],
  [ACTIONS.PRINT_WORKORDER]: [
    ROLE_ENUM.ADMIN,
    ROLE_ENUM.CSR,
    ROLE_ENUM.CSR_MANAGER,
    ROLE_ENUM.ACCOUNTING,
    ROLE_ENUM.SHIPPING_SPECIALIST,
    ROLE_ENUM.SHIPPING_MANAGER,
    ROLE_ENUM.PRODUCTION_MANAGER,
    ROLE_ENUM.PRODUCTION_SCHEDULER,
    ROLE_ENUM.TECHNICAL_ADVISOR,
    ROLE_ENUM.ORDER_PROCESSOR_2,
    ROLE_ENUM.LMS_TEAM_LEAD,
    ROLE_ENUM.SPECIALIST_TEAM,
    ROLE_ENUM.INTERNAL_LOGISTICS_TEAM_LEAD,
    ROLE_ENUM.EVALUATION,
    ROLE_ENUM.SHIPPING_TEAM_LEAD,
    ROLE_ENUM.PRODUCTION_TEAM_LEAD,
  ],
  [ACTIONS.APPLY_DISCOUNT]: [
    ROLE_ENUM.ADMIN,
    ROLE_ENUM.PRODUCTION_MANAGER,
    ROLE_ENUM.TECHNICAL_ADVISOR,
    ROLE_ENUM.INTERNAL_LOGISTICS_TEAM_LEAD,
    ROLE_ENUM.SPECIALIST_TEAM,
    ROLE_ENUM.SHIPPING_MANAGER,
    ROLE_ENUM.ORDER_PROCESSOR_2,
    ROLE_ENUM.SHIPPING_TEAM_LEAD,
    ROLE_ENUM.CSR_MANAGER,
    ROLE_ENUM.ACCOUNTING,
  ],
  [ACTIONS.APPLY_CREDIT]: [ROLE_ENUM.ADMIN, ROLE_ENUM.CSR_MANAGER, ROLE_ENUM.ACCOUNTING],
  [ACTIONS.EDIT_CASE_STATUS]: [
    ROLE_ENUM.ADMIN,
    ROLE_ENUM.PRODUCTION_MANAGER,
    ROLE_ENUM.SPECIALIST_TEAM,
    ROLE_ENUM.INTERNAL_LOGISTICS_TEAM_LEAD,
    ROLE_ENUM.TECHNICAL_ADVISOR,
    ROLE_ENUM.PRODUCTION_SCHEDULER,
  ],
  [ACTIONS.EDIT_RETURN_TYPE]: [
    ROLE_ENUM.ADMIN,
    ROLE_ENUM.SPECIALIST_TEAM,
    ROLE_ENUM.PRODUCTION_MANAGER,
    ROLE_ENUM.PRODUCTION_TEAM_LEAD,
    ROLE_ENUM.EVALUATION,
    ROLE_ENUM.ORDER_PROCESSOR_2,
    ROLE_ENUM.SHIPPING_MANAGER,
    ROLE_ENUM.SHIPPING_TEAM_LEAD,
  ],
} as const;

export const PERMISSION_DENIED = 'Permission Denied';
export const PERMISSION_DENIED_TEXT = 'Please contact an administrator for access to perform this action.';

export type APP_PERMISSION_KEY = keyof typeof APP_PERMISSION_LIST;
